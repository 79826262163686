<template>
    <div class="newproblems">
        <ul class="newproblems-wrap">
            <router-link :to="'/pagedetails?id='+item.news_id" tag="div" v-for="(item,index) in agreementlist"
                :key="index">
                <li>{{item.title}}</li>
            </router-link>
        </ul>
    </div>
</template>

<script>
    import {
        AgreementList,
        wenDaList,
        shopwenda
    } from "../../../http/api";
    export default {
        data() {
            return {
                type: this.$route.query.type,
                pages: 1,
                loadLastText: false,
                agreementlist: []
            };
        },
        mounted() {
            this.AgreementList();
        },
        methods: {
            async AgreementList() {
                let res;
                if (this.type == "1") {
                    res = await wenDaList({
                        data: {
                            pages: this.pages
                        }
                    });
                }
                if (this.type == "2") {
                    res = await AgreementList({
                        data: {
                            pages: this.pages
                        }
                    });
                }
                if (this.type == "3") {
                    res = await shopwenda({
                        data: {
                            pages: this.pages
                        }
                    });
                }
                console.log(res);
                if (res && res.data.length > 0) {
                    this.pages++;
                    this.agreementlist = this.agreementlist.concat(res.data);
                    this.loadLastText = true;
                } else {
                    this.loadLastText = false;
                }
            }
        },
        created() {
            if (this.type == "1") {
                document.title = "新手问答";
            }
            if (this.type == "2") {
                document.title = "相关协议";
            }
            if (this.type == "3") {
                document.title = "常见问题";
            }
        }
    };
</script>

<style lang="scss">
    .newproblems {
        width: 100%;
        height: 100%;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .newproblems-wrap {
            width: 100%;
            padding-left: 0.3rem;
            padding-bottom: 0.3rem;
            box-sizing: border-box;

            li {
                padding-top: 0.73rem;
                padding-bottom: 0.1rem;
                box-sizing: border-box;
                font-size: 0.28rem;
                border-bottom: 1px solid #e6e6e6;
            }
        }
    }
</style>
